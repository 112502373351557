<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : 'Add'}` }} stop
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="stop.title" outlined dense label="Stop title">
                            </v-text-field>
                            <span class="text-danger" v-if="$v.stop.title.$error">This information is required</span>
                        </v-col>
                        <v-col cols="6">

                            <v-select :items="routes" item-value="id" item-text="title" v-model="stop.route_id" outlined
                                dense label="Select route">

                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="stop.time" type="time" outlined dense label="Time ">

                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="stop.latitude" outlined dense label="Latitude">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="stop.longitude" outlined dense label="Longitude">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label for="">Description</label>
                            <ckeditor v-model="stop.description" :config="editorConfig"></ckeditor>

                        </v-col>

                        <v-col cols="4">
                            Status
                            <v-switch :label="stop.is_active ? 'Active' : 'Inactive'" v-model="stop.is_active" outlined dense>

                            </v-switch>

                        </v-col>

                        <v-col cols="12">
                            <div class="float-right">
                                <v-btn depressed @click="dialog = false;"
                                    class="text-gray btn btn-standard cancel-btn">Cancel</v-btn>
                                <v-btn v-if="checkIsAccessible('stops', 'create')" depressed :loading="isBusy"
                                    @click="createOrUpdate" class="ml-2 text-white btn btn-primary">Save</v-btn>
                            </div>

                        </v-col>

                    </v-row>
                </v-card-text>

            </v-card>

        </v-dialog>

    </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import StopsService from "@/core/services/Transport/StopsService";
const stopsService = new StopsService();

import RoutesService from "@/core/services/Transport/Routes/RoutesService";
const routesService = new RoutesService();
import 'vue-datetime/dist/vue-datetime.css'
export default {
    validations: {
        stop: {
            title: { required }
        }
    },
    props: ['route'],
    data() {
        return {
            edit: false,
            dialog: false,
            sizeExceed: false,
            isBusy: false,
            routes: [],
            stop: {
                title: null,
                description: null,
                route_id: null,
                latitude: null,
                longitude: null,
                is_active: true
            },
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
        };
    },
    methods: {
        showModal(id = null) {
            if (id) {
                this.edit = true
                this.__get(id)
            } else {
                this.edit = false;
                this.stop = {
                    title: null,
                    route_id: null,
                    time: null,
                    description: null,
                    is_active: true,
                };
            }
            this.dialog = true;
            if (this.route) {
                this.stop.route_id = this.route.id;
            }
            this.__getRoutes();
        },
        hideModal() {
            this.dialog = false;

        },
        toggleModal() {
            this.$refs["my-modal"].toggle("#toggle-btn");
        }, __get(id) {
            stopsService.show(id).then(response => {
                this.stop = response.data.stops
            })
        },


        __getRoutes() {
            routesService
                .paginate()
                .then(response => {
                    this.routes = response.data.data;
                })
                .catch(error => {
                    // console.log(error);
                });
        },
        createOrUpdate() {
            this.$v.stop.$touch();
            if (this.$v.stop.$error) {
                setTimeout(() => {
                    this.$v.stop.$reset();
                }, 3000);
            } else {
                let fd = this.stop;
                if (this.edit) {
                    this.updateStop();
                } else {
                    this.createStop();
                }
            }
        },
        updateStop() {
            this.isBusy = true;
            stopsService
                .update(this.stop.id, this.stop)
                .then(response => {
                    this.isBusy = false;
                    this.$snotify.success("Information updated");
                    this.resetForm();
                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                    // console.log(error);
                });
        },


        createStop() {
            this.isBusy = true;
            stopsService
                .store(this.stop)
                .then(response => {
                    this.isBusy = false;
                    this.resetForm();
                    this.$snotify.success("Information added");

                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                    // console.log(error);
                });
        },
        resetForm() {
            this.$v.stop.$reset();
            this.edit = false;
            this.stop = {
                title: null,
                route_id: null,
                time: null,
                description: null,
                is_active: true,
            };
            this.hideModal();
            this.$emit("refresh");
        }
    }
};
</script>
